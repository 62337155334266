<template>
  <section class="dnc-accept" v-if="isLoaded">
    <div class="dnc-accept__wrapper dnc-wrapper">
      <div class="dnc-accept__img">
        <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
          <path d="M512 64a448 448 0 1 1 0 896 448 448 0 0 1 0-896zm-55.808 536.384-99.52-99.584a38.4 38.4 0 1 0-54.336 54.336l126.72 126.72a38.272 38.272 0 0 0 54.336 0l262.4-262.464a38.4 38.4 0 1 0-54.272-54.336L456.192 600.384z"/>
        </svg>
      </div>
      <h1 class="dnc-personal-info__header dnc-accept__header">Оплачено</h1>
      <div class="dnc-personal-info__description">
        <p>Оплата прошла успешно. <br /> Вы можете начать обучение.</p>
      </div>
      <div class="dnc-personal-info__btn">
        <NuxtLink class="dnc-button dnc-button-secondary" :to="{name: 'mentor-list'}">Выбрать ментора</NuxtLink>
      </div>
    </div>
  </section>
</template>

<script setup>
import { getPayment } from '~/api/payment'

useHead({
  title: 'Оплачено'
})

const route = useRoute()
const router = useRouter()
const isLoaded = ref(false)

onMounted(async () => {
  if (route.query.PaymentId) {
    const payloadData = await getPayment(route.query.PaymentId)

    if (payloadData.payload?.mentor_id) {

      await router.push({
        name: 'mentor-book',
        params: {
          id: payloadData.payload.mentor_id
        },
        query: {
          lessons: payloadData.payload.datetimes_start.join(',')
        }
      })
    }
  }

  isLoaded.value = true
})
</script>

<style scoped>
.dnc-accept {
  width: 100%;
  max-width: 340px;
}
 .dnc-accept,
 .dnc-accept .dnc-personal-info__header {
   text-align: center;
 }

.dnc-accept__header {
  text-align: left;
}
svg {
  display: block;
  width: 150px;
  margin: 0 auto;
}
svg path {
  fill: var(--color-primary);
}
</style>
